@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap");

:root {
  background-color: #f2f6f6;
  --labelColor: #5d8d92;
  --textFieldColor: #808080;
  --primaryColor: #76b3b8;
}

.amplify-textfield {
  --amplify-components-field-label-color: var(--labelColor);
}

.amplify-flex .amplify-field {
  color: pink;
  --amplify-components-field-label-color: #43898e;
}

.indicators {
  width: 100%;
  margin: auto;
  padding-right: 30px;
}

.indicators li {
  width: 10px;
  height: 10px;
  background-color: #ffffff !important;
  color: aquamarine !important;
  border-radius: 4px;
  background: #ffffff !important;
}

.react-slideshow-container + ul.indicators {
  display: flex !important;
  flex-wrap: wrap !important;
  margin-top: 4px !important;
  width: 100% !important;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  background-color: #aec4c6 !important;
  width: 10px !important;
  height: 10px !important;
}
.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  background-color: #aec4c6 !important;
  width: 10px !important;
  height: 10px !important;
}

.react-slideshow-container + ul.indicators li {
  display: inline-block;
  position: relative;
  margin: 4px !important;
}

amplify-s3-album {
  width: 100% !important;
  height: 100px !important;
}

amplify-s3-image img {
  background-color: aqua !important;
  width: 50px !important;
  height: 50px !important;
  margin-top: 20px !important;
  border-radius: 20px !important;
}

.amplify-flex .amplify-select__icon-wrapper {
  color: #5b8386 !important;
}

/* green loading */

@keyframes ldio-d5ovkz6p4dk {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-d5ovkz6p4dk div {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1.5px solid #5b8386;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-d5ovkz6p4dk div {
  animation: ldio-d5ovkz6p4dk 1s linear infinite;
  top: 100px;
  left: 100px;
}
.loadingio-spinner-rolling-zctqyswbwvg {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-d5ovkz6p4dk {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-d5ovkz6p4dk div {
  box-sizing: content-box;
}

/* white loading */

@keyframes ldio-d5ovkz6p4dk2 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-d5ovkz6p4dk2 div {
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1.5px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-d5ovkz6p4dk2 div {
  animation: ldio-d5ovkz6p4dk 1s linear infinite;
  top: 50px;
  left: 50px;
}
.loadingio-spinner-rolling-zctqyswbwvg2 {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ldio-d5ovkz6p4dk2 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-d5ovkz6p4dk2 div {
  box-sizing: content-box;
}
